import React from 'react'
import { FiGithub, FiLinkedin } from 'react-icons/fi'

function Footer({ isDarkMode }) {
  return (
    <footer>
      <div className="footer-social">
        <a
          className="icon-social"
          target="_blank"
          href="https://www.linkedin.com/in/filipecalm/"
          rel="noopener noreferrer"
          aria-label="LinkedIn de Filipe Almeida"
        >
          {/* Cor baseada no estado do tema */}
          <FiLinkedin size={35} color={isDarkMode ? 'white' : 'black'} />
        </a>
        <a
          className="icon-social"
          target="_blank"
          href="https://github.com/filipecalm"
          rel="noopener noreferrer"
          aria-label="GitHub de Filipe Almeida"
        >
          {/* Cor baseada no estado do tema */}
          <FiGithub size={35} color={isDarkMode ? 'white' : 'black'} />
        </a>
      </div>
      <p className="items-center">
        feito com &#x2764; por <strong>&copy;Filipe Almeida</strong>
      </p>
    </footer>
  )
}

export default Footer