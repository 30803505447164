import React, { useEffect } from 'react'
import { IoMdSunny, IoMdMoon } from 'react-icons/io'
import './style.css'

function Header({ isDarkMode, setIsDarkMode }) {

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode)
  }

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
    } else {
      document.documentElement.classList.add('light')
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  return (
    <header id="title" className={`nav ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="nav-links">
        {/* Corrigindo os links para redirecionar para a raiz ou para páginas corretas */}
        <a className={`${isDarkMode ? 'dark' : 'light'}`} href="/">
          Início
        </a>
        <a className={`${isDarkMode ? 'dark' : 'light'}`} href="/#sobre">
          Sobre
        </a>
        <a className={`${isDarkMode ? 'dark' : 'light'}`} href="/#projetos">
          Projetos
        </a>
        <a className={`${isDarkMode ? 'dark' : 'light'}`} href="/#contato">
          Contato
        </a>
        <div className="theme-switcher" onClick={toggleTheme}>
          {isDarkMode ? <IoMdSunny /> : <IoMdMoon />}
        </div>
      </div>
    </header>
  )
}

export default Header
